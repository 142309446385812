@mixin mq($breakpoint, $query: 'min-width', $type: 'screen') {
  @media #{$type} and (#{$query}: #{$breakpoint}) {
    @content;
  }
}

@mixin on-desktop {
  @include mq('768px') {
    @content;
  }
}

@mixin on-tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin on-large-tablet {
  @media only screen and (min-width: 768px) and (max-width: 1210px) {
    @content;
  }
}
@mixin on-wide-desktop {
  @include mq('1024px') {
    @content;
  }
}
